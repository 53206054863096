<template>
    <div class="text-left">
      <div class="user-input-control d-flex align-items-center border px-3 py-1 justify-content-around my-2"
        :class="{ 'focused': isInputFocused }"
      :style="userInputControlStye(index)" v-for="(option,index) in options" :key="option.id">
          <div class="input-number " :style="circleStyle(index)">
              {{ index+1 }}
          </div>
          <div class="user-input  mx-2" style="flex:0.7">
              <input type="number" @focus="isInputFocused = index" @blur="isInputFocused = -1" v-model.number="option.value" style="background-color: transparent;">
          </div>
          <div class="user-text" style="flex:0.3;text-align: right;">
              mm
          </div>
      </div>
      
    </div>
  </template>
  
  <script>
  export default {
  props:{
      pro:Object
  },
  data(){
      return {
          isInputFocused:-1,
          options:[...this.pro.options]
      }
  },
  computed:{
      style(){
          return this.pro.style
      },
  },
  watch:{
      options:{
          handler(newValue){
              let checkAllFields=true;
              for(let key in newValue)
              {
                 if(typeof newValue[key].value!='number' || newValue[key].value<=0)
                 {
                  checkAllFields=false;
                  break;
                 }
                  
              }
              if(checkAllFields)
              this.$emit('selectedOptions',newValue)
              else
              this.$emit('selectedOptions',[])
          },
          deep: true
      }
  },
  methods:{
      userInputControlStye(index){
          const {borderRadius,color,fontSize,fontWeight,selectedTab} = this.style
          return {'border-radius':borderRadius+'px',color:color,fontSize:fontSize+'px',fontWeight:fontWeight, borderColor:this.isInputFocused==index?selectedTab.backgroundColor+'!important':''}
      },
       circleStyle(index){
          const {selectedTab} = this.style
          const checkIndex = this.isInputFocused==index
          return { background:checkIndex?selectedTab.backgroundColor+'!important':'#F3F1EB',color:checkIndex?selectedTab.textColor+'!important':'inherit'}
      },
      
  }
  }
  </script>
  
  <style scoped>
  .input-number{
      border-radius: 50%;
      min-width: 20px;
      min-height: 20px;
      line-height: 20px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
  }
  .user-input input{
      height: 15px;
      width: 100%;
      border: none;
  }
  .user-input input:focus-visible{
      border:none;
      outline: none;
  }
  </style>